<template>
  <OrganismMap :clubs="clubs" />
</template>

<script>
import OrganismMap from '../../components/organisms/layouts/OrganismMap'

export default {
  name: 'ClubsMap',
  components: { OrganismMap },
  meta: {
    theme: 'dark',
  },
  props: {
    context: {
      default: () => {},
      required: false,
      type: Object,
    },
  },
  computed: {
    clubs() {
      const cityId = this.context.cityId
      return this.$store.getters['modules/content/clubs'].filter(({ city }) =>
        cityId !== 'all' ? city.id === cityId : true,
      )
    },
  },
}
</script>
